/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React from 'react'
import { get, isFunction } from 'lodash'
import { graphql } from 'gatsby'

import Introduction from '../components/introduction'
import { FullHeader } from '../components/page-header'
import PastProductions from '../components/past-productions'
import PageWrapper from '../components/page-wrapper'

import { Space } from '../styles/els'
import { useThemeUISetValue } from '../styles/utils'

import { formatImage } from '../lib/helpers'

const PastProductionsPage = (props) => {
  const { data } = props

  const { theme } = useThemeUI()
  const pT = useThemeUISetValue('headingPadding', theme)

  const title = get(data, 'page.title')
  const headerImage = formatImage(get(data, 'page.acfPage.headerImage'))

  return (
    <PageWrapper>
      <FullHeader image={headerImage} />
      <Space space={pT} />
      {title ? <Introduction title={title} /> : null}
      <PastProductions />
    </PageWrapper>
  )
}

export default PastProductionsPage

export const query = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      template {
        templateName
      }
      acfPage {
        pageColour
        headerImage {
          ...FullsizeImage
        }
      }
    }
  }
`
